@import 'Styles/Base/includes';

/**
*
* ExtendedWysiwyg
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ExtendedWysiwyg {
    $root: &;

    &:empty {
        display: none;
    }

    :global(.Wysiwyg) + & {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    &__Block {
        &--Form {
            margin: 20px 0;
        }

        &--ContactList,
        &--HtmlSnippet,
        &--Reviews,
        &--Accordion,
        &--Faq {
            margin: 40px 0;
        }
    }
}
