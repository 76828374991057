@import 'Styles/Medborgarskolan/includes';

/**
*
* AccordionList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'AccordionList.Base.module.scss';

.AccordionList {
    $root: &;

    border-color: $blue-20;

    &__Title {
        margin-bottom: 12px;
        color: $blue;
        font-size: 2rem;
        line-height: 1.27;

        @include media(sm) {
            margin-bottom: 16px;
            font-size: 2.2rem;
        }
    }
}
