@import 'Styles/Medborgarskolan/includes';

/**
*
* SideBarBlurb
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.SideBarBlurb {
    $root: &;

    position: relative;
    padding: 32px 24px;
    margin-top: 30px;
    border-top: 8px solid $blue;
    border-radius: 8px;
    background-color: $blue-20;

    &--News {
        background-color: $blue-20;
    }

    &__Title {
        margin-bottom: 10px;
        color: $blue;
        font-size: 2.2rem;

        @include media(sm) {
            font-size: 2.6rem;
        }
    }

    &__Link {
        margin-top: 20px;

        &:hover {
            text-decoration: underline;
        }
    }
}
