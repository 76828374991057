@import 'Styles/Pahlmans/includes';

/**
*
* AccordionItem
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'AccordionItem.Base.module.scss';

.AccordionItem {
    $root: &;

    border-color: palette(black, 15);

    &--Open {
        background: white
    }

    &__Heading {
        padding: 25px 60px 30px;
        color: $primary-color;
        font-family: $font-base;
        font-size: 1.8rem;

        &::before {
            content: '';
            position: absolute;
            top: 29px;
            left: 27px;
            width: 6px;
            height: 6px;
            border-color: $primary-color;
            transition: all 0.2s ease-in;
        }

        #{$root}--Open & {
            background: white;

            &::before {
                top: 32px;
            }
        }
    }

    &__Content {

        #{$root}--Open & {
            padding: 0 60px 50px;
        }
    }
}
