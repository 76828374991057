@import 'Styles/Medborgarskolan/includes';

/**
*
* CardImageText
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardImageText.Base.module.scss';

.CardImageText {

    &__Image {

        @include media(xs) {
            margin-right: 24px;
            max-width: 34%;
        }
    }

    :global(.Wysiwyg) {
        color: $text-color;

        > h2,
        > h3,
        > h4,
        > h5,
        > h6 {
            &:first-child {
                margin-top: 0;
            }
        }
    }
}
