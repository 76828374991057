@import 'Styles/Medborgarskolan/includes';

/**
*
* EventSearchSubjectList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EventSearchSubjectList {
    $root: &;

    background: $blue-20;

    &--NoSubjects {
        padding-bottom: 48px;
    }

    &__Search {
        @include u-wrap(site);

        padding-top: 48px;

        #{$root}--NoTopSpacing & {
            padding-top: 0;
        }
    }

    &__SearchTitle {
        margin-bottom: 24px;
        font-size: 3.4rem;
        line-height: 42px;
        color: $blue;

        @include media(sm) {
            margin-bottom: 40px;
            font-size: 4.8rem;
        }
    }
}
