@import 'Styles/Kulturama/includes';

/**
*
* EventFacts
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventFacts.Base.module.scss';

.EventFacts {
    $root: &;

    &--Calendar {
        background-color: #f8f8f8;
    }

    &__Container {
        width: 100%;
        padding-top: 30px;

        #{$root}--Calendar & {
            @include u-wrap(site);
            
            padding-top: 50px;
            padding-bottom: 40px;
        }
    }

    &__Title {
        display: none;

        @include media(sm) {
            display: block;
            margin-bottom: 20px;
            font-family: $font-heading;
            font-weight: $bold;
            font-size: 1.4rem;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    &__List {
        width: 100%;

        @include media(sm) {
            margin-left: -20px;
        }
    }

    &__Item {
        margin-bottom: 20px;
        display: flex;

        @include media(sm) {
            flex-direction: column;
            width: calc(50% - 20px);
            margin-left: 20px;
        }

        @include media(md) {
            width: calc(#{percentage(1/3)} - 20px);
        }
    }

    &__Label {
        color: palette(black, 50);
        font-size: 1.2rem;
        font-weight: $medium;
        text-transform: uppercase;
        letter-spacing: 1px;
        min-width: 40%;

        @include media(sm) {
            width: auto;
        }
    }

    &__Value {
        font-size: 1.6rem;
        font-weight: $medium;
        max-width: 60%;

        a {
            color: palette(pink);
        }

        @include media(sm) {
            font-weight: $normal;
            max-width: 100%;
        }
    }
}
