@import 'Styles/Medborgarskolan/includes';

/**
*
* ExtendedWysiwyg
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ExtendedWysiwyg.Base.module';

.ExtendedWysiwyg {
    $root: &;

    :global(.Wysiwyg) + & {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__Block {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
