@import 'Styles/Base/includes';

/**
*
* CardImageText
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardImageText {
    padding-top: 20px;
    padding-bottom: 30px;

    @include media(xs) {
        display: flex;
    }

    &__Image {
        height: auto;
        width: 100%;
        align-self: center;
    }

}
