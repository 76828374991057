@import 'Styles/Base/includes';

/**
*
* AccordionItem
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.AccordionItem {
    $root: &;

    border-top: 1px solid;

    &__Heading {
        position: relative;
        cursor: pointer;
        z-index: get-default($z-index);


        &::before {
            content: '';
            position: absolute;
            background: transparent;
            border-top: 2px solid;
            border-right: 2px solid;
            transform: rotate(135deg);
            transform-origin: 50%;
        }

        #{$root}--Open & {
            padding-bottom: 0;

            &::before {
                transform: rotate(-45deg);
            }
        }
    }

    &__Content {
        height: 0;
        overflow: hidden;
        transform: translateY(-80px);
        transition: transform .6s cubic-bezier(.20,1,.22,1);

        #{$root}--Open & {
            height: auto;
            transform: translateY(0);
        }
    }
}
