@import 'Styles/Base/includes';

/**
*
* EventFacts
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EventFacts {

    &__List {
        display: flex;
        flex-flow: row wrap;
    }

    &__Item {
        width: 100%;
    }
}
