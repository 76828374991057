@import 'Styles/Medborgarskolan/includes';

/**
*
* Share
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import './Share.Base.module.scss';

.Share {
    $root: &;

    &__Title {
        display: none;
    }

    &__Item {
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 50%;
        background-color: white;

        #{$root}--Event & {
            background-color: $blue-10;
        }

        &::before {
            border: none;
            border-radius: 50%;
            background-color: $blue-20;
        }

        &::after {
            display: none;
        }

        svg {
            display: inline-block;
            * {
                fill: $blue;
            }
        }
    }
}
