@import 'Styles/Medborgarskolan/includes';

/**
*
* LinkList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'LinkList.Base.module';

.LinkList {
    $root: &;

    margin-top: 48px;
    margin-bottom: 28px;

    @include media(md) {
        margin-top: 72px;
        margin-bottom: 60px;
    }

    &__Container {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    &__Title {
        margin-bottom: 6px;
        font-size: 3.2rem;
        color: $blue;

        @include media(md) {
            margin-bottom: 10px;
            font-size: 4.8rem;
        }
    }

    &__Text {
        font-size: 2rem;
    }

    &__List {
        display: flex;
        flex-flow: row wrap;
        margin-top: 30px;
        margin-left: -24px;

        @include media(md) {
            margin-top: 40px;
        }
    }

    &__Item {
        margin-left: 24px;
        margin-bottom: 20px;
        padding: 28px 24px;
        background-color: $blue-20;
        border-radius: 8px;

        @include media(md) {
            flex: 1 1 auto;
            width: calc(50% - 80px);
            margin-bottom: 20px;
        }
    }

    &__Link {
        display: block;
        z-index: z();
    }

    &__ItemTitle {
        margin-bottom: 5px;
        font-size: 2.6rem;
        color: $blue;

        #{$root}__Item:hover & {
            text-decoration: underline;

            @media(hover: none) {
                text-decoration: none;
            }
        }
    }

    &__ItemText {
        font-size: 1.8rem;
    }

    &__ItemLabel {

        svg {

            * {
                fill: $blue;
            }
        }
    }

    &__Arrow {
        width: 24px;
        height: 24px;
        margin-left: 8px;
        background: url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow--blue.svg') no-repeat center center;
        background-size: contain;

        svg {
            display: none;
        }
    }

    &__Button {
        display: none;
    }
}
