@import 'Styles/Dansskolanentre/includes';

/**
*
* AccordionList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'AccordionList.Base.module.scss';

.AccordionList {
    margin: 40px 0;
    border-color: $grey-5;

    @include media(md) {
        margin: 60px 0;
    }

    &__Title {
        font-size: 1.8rem;
        font-family: $font-base;
        font-weight: $bold;
    }
}
