@import 'Styles/Pahlmans/includes';

/**
*
* LinkList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'LinkList.Base.module';

.LinkList {
    $root: &;

    padding-top: 40px;
    padding-bottom: 10px;
    background: white;

    :global(.PageOfferSchool) & {
        background: none;
    }

    @include media(md) {
        padding-top: 80px;
        padding-bottom: 45px;
    }

    &--Columns4{
        background: rgba(white,0);

        @include media(md) {
            padding-bottom: 80px;
        }
    }

    &__Title {
        margin: 0 10px 35px;

        @include media(md) {
            margin: 0 0 40px;
        }
    }

    &__Text {
        margin-top: -30px;
        margin-bottom: 35px;
        @include media(md) {
            margin-bottom: 40px;
        }
    }

    &__List {
        margin: 0 10px;

        @include media(md) {
            display: flex;
            flex-flow: row wrap;
            margin: 0 0 0 -10px;
        }
    }

    &__Item {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        @include media(md) {
            margin-left: 10px;
            width: calc(33.33% - 10px);

            #{$root}--Columns4 & {
                width: calc(25% - 10px);
            }
        }
    }

    &__Link {
        display: block;
        z-index: z();
    }

    &__ItemContainer {
        flex: 1 0 auto;
        padding: 24px 24px 64px;
        border: 1px solid palette(black, 10);
        border-radius: 4px;
        background: white;

        @include media(sm) {
            padding: 32px 32px 72px;
        }
    }

    &__ItemTitle {
        word-break: break-word;
        margin-bottom: 10px;
        color: black;
        font-weight: $medium;
        transition: color .3s ease-in-out;

        @include media(sm) {
            font-size: 2.8rem;
        }

        #{$root}__Item:hover & {
            color: $primary-color;

            @media(hover: none) {
                color: inherit;
            }
        }
    }

    &__ItemText {
        font-size: 1.6rem;
        color: palette(black, 68);

        #{$root}--Columns4 & {
            font-size: 1.4rem;
        }
    }

    &__Button {
        display: inline-block;
        position: absolute;
        left: 24px;
        bottom: 24px;
        width: 23px;
        height: 15px;
        background-image: $bgArrow;
        background-repeat: no-repeat;
        transition: transform .3s ease-in-out;

        svg {
          display: none;
        }

        span {
            display: none;
        }

        @include media(sm) {
            left: 32px;
            bottom: 32px;
        }

        #{$root}__Item:hover & {
            text-decoration: underline;
            transform: translateX(5px);

            @media(hover: none) {
                text-decoration: none;
                transform: none;
            }
        }
    }

    &__Arrow--Medborgarskolan {
        display: none;
    }
}
