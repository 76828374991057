@import 'Styles/Dansskolanentre/includes';

/**
*
* Share
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import './Share.Base.module.scss';

.Share {
    $root: &;

    &__Title {
        color: $grey-70;
        font-size: 1.4rem;
        font-weight: $regular;
        line-height: 1.5;
    }

    &__Item {
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 50%;
        background: $red-5;

        #{$root}--Event & {
            background: $red-5;
        }

        &::before {
            border: none;
            border-radius: 50%;
            background: $gradient-color;
            opacity: 0;
            transition: all 0.3s ease-in-out;

            &:hover {
                opacity: 1;
            }
        }

        &::after {
            display: none;
        }

        svg {
            display: inline-block;
            transition: all 0.3s ease-in-out;
            * {
                fill: $primary-color;
            }
        }

        &:hover {
            svg {
                * {
                    fill: $red-5;
                }
            }
        }

    }
}

