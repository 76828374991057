@import 'Styles/Pahlmans/includes';

/**
*
* CardReview
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardReview.Base.module';

.CardReview {
    $root: &;

    border-color: palette(black, 15);
    border-radius: 4px;

    svg {
        fill: $primary-color;
    }

    &__Title {
        color: palette(black, 86);
    }

    &__Label {
        color: palette(black, 86);
    }

    &__Date {
        color: palette(black, 72);
    }

    &__Text {
        color: palette(black, 86);
    }
}
