@import 'Styles/Base/includes';

/**
*
* Share
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Share {
    $root: &;

    display: flex;
    align-items: center;
    gap: 16px;

    &__Title {
        font-size: 1.6rem;
        margin-right: 4px;

        #{$root}--Small & {
            font-size: 1.4rem;
            margin-right: 0;
        }
    }

    &__Item {
        display: block;
        flex: 0 0 auto;
        position: relative;
        width: 32px;
        height: 32px;

        &:hover {
            &:before {
                opacity: 1;
            }
        }

        &::before {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border: 1px solid;
            opacity: 0;
            transition: opacity .3s ease-in-out;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }

        &--LinkedIn {
            display: none;
        }

        svg {
            display: none;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;

            * {
                transition: fill .3s ease-in-out;
            }
        }
    }
}
