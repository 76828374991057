@import 'Styles/Base/includes';

/**
*
* CardReview
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardReview {
    $root: &;

    display: flex;
    position: relative;
    padding: 16px 16px 24px;
    border: 1px solid;
    background: white;

    @include media(sm) {
        padding: 24px;
    }

    svg {
        position: absolute;
        top: -8px;
        right: 16px;
        width: 44px;
        height: 32px;

        @include media(sm) {
            top: -16px;
            right: 24px;
            width: 55px;
            height: 40px;
        }
    }

    &__Image {
        position: absolute;
        top: 16px;
        left: 16px;
        width: 88px;
        height: 88px;
        margin-right: 16px;

        @include media(sm) {
            flex: 0 0 auto;
            position: relative;
            top: auto;
            left: auto;
            width: 96px;
            height: 96px;
            margin-right: 24px;
        }
    }

    // Absolute position image in mobile
    &__Header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 20px;

        @include media(sm) {
            margin-bottom: 16px;
        }

        #{$root}--HasImage & {
            min-height: 88px;
            padding: 7px 0 0 (88px + 16px);

            @include media(sm) {
                padding: 0;
                min-height: 0;
            }
        }
    }

    &__Title {
        font-size: 1.8rem;
        font-weight: $semibold;
        line-height: 24px;

        #{$root}:not(#{$root}--HasImage) & {
            padding-right: 44px;
        }
    }

    &__Label {
        margin-top: 4px;
        font-size: 1.4rem;
        line-height: 1.52;
    }

    &__Date {
        margin-top: 7px;
        font-size: 1rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        line-height: 1.52;
    }

    &__Text {
        font-size: 1.6rem;
        line-height: 24px;

        @include media(sm) {
            font-size: 1.8rem;
            line-height: 28px;
        }
    }
}
