@import 'Styles/Base/includes';

/**
*
* FAQItem
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FAQItem {
    $root: &;

    border-top: 1px solid black;

    &--Open {
        background: white
    }

    &__Heading {
        position: relative;
        padding: 24px 56px 24px 16px;
        color: black;
        font-family: $font-base;
        font-size: 1.6rem;
        line-height: 1.53;
        cursor: pointer;
        z-index: get-default($z-index);

        @include media(sm) {
            padding: 26px 72px 26px 24px;
            font-size: 1.8rem;
        }

        #{$root}--Open & {
            padding-bottom: 0;
            background: white;
        }
    }

    &__HeadingText {
        display: block;
        max-width: map-get($maxwidths, content);
    }

    &__Icon {
        position: absolute;
        top: 20px;
        right: 16px;
        width: 32px;
        height: 32px;
        background-color: black;
        border-radius: 50%;

        @include media(sm) {
            top: 22px;
            right: 24px;
            width: 36px;
            height: 36px;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: 12px;
            height: 2px;
            margin: auto;
            background: white;
            transform-origin: 50%;
            transition: transform .2s ease-in-out;

            @include media(sm) {
                width: 15px;
            }
        }

        &::after {
            transform: rotate(90deg);

            #{$root}--Open & {
                transform: rotate(180deg);
            }
        }
    }

    &__Content {
        max-width: map-get($maxwidths, content) + 32px;
        height: 0;
        overflow: hidden;
        transform: translateY(-80px);
        transition: transform .6s cubic-bezier(.20,1,.22,1);

        @include media(sm) {
            max-width: map-get($maxwidths, content) + 48px;
        }

        #{$root}--Open & {
            height: auto;
            padding: 0px 16px 24px;
            transform: translateY(0);

            @include media(sm) {
                padding: 0px 24px 32px;
            }
        }
    }

    &__Wysiwyg {
        margin-top: 16px;
    }
}
