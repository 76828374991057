@import 'Styles/Medborgarskolan/includes';

/**
*
* AccordionItem
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'AccordionItem.Base.module.scss';

.AccordionItem {
    $root: &;

    border-color: $blue-20;

    &--Open {
        background: white
    }

    &__Heading {
        max-width: map-get($maxwidths, content);
        padding: 24px 16px;
        color: $blue;
        font-family: $font-base;
        font-size: 1.6rem;
        line-height: 1.53;

        @include media(sm) {
            padding: 26px 24px;
            font-size: 1.8rem;
        }

        &::before {
            top: 30px;
            right: 19px;
            width: 6px;
            height: 6px;
            border-color: $blue;
            transition: transform .2s, top .2s;

            @include media(sm) {
                top: 34px;
                right: 24px;
            }
        }

        #{$root}--Open & {
            background: white;

            &::before {
                top: 32px;

                @include media(sm) {
                    top: 36px;
                }
            }
        }
    }

    &__Content {
        max-width: map-get($maxwidths, content);

        #{$root}--Open & {
            padding: 14px 16px 24px;

            @include media(sm) {
                padding: 17px 24px 32px;
            }
        }
    }
}
