@import 'Styles/Kulturama/includes';

/**
*
* LinkList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'LinkList.Base.module';

.LinkList {
    $root: &;
    
    padding-top: 75px;
    padding-bottom: 30px;
    background: palette(black, 5);

    @include media(md) {
        padding-top: 80px;
        padding-bottom: 45px;
    }

    &__Title {
        margin-bottom: 35px;

        @include media(md) {
            margin-bottom: 40px;
        }
    }

    &__Text {
        margin-top: -30px;
        margin-bottom: 35px;

        @include media(md) {
            margin-bottom: 40px;
        }
    }

    &__List {
        display: flex;
        flex-flow: row wrap;
        margin-left: -80px;
    }

    &__Item {
        margin-left: 80px;
        margin-bottom: 40px;

        @include media(md) {
            width: calc(33.33% - 80px);
        }
    }

    &__Link {
        display: block;
        z-index: z();
    }

    &__ItemTitle {
        margin-bottom: 5px;
        font-size: 2rem;
    }

    &__ItemLabel {

        svg {

            * {
                fill: palette(pink);
            }
        }
    }

    &__ItemText {
        font-size: 1.4rem;
    }

    &__Button {
        @include u-button(arrow, pink);
        display: inline-block;
        margin-top: 10px;

        #{$root}__Item:hover & {
            text-decoration: underline;

            @media(hover: none) {
                text-decoration: none;
            }
        }
    }

    &__Arrow--Medborgarskolan {
        display: none;
    }
}
