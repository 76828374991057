@import 'Styles/Pahlmans/includes';

/**
*
* FAQList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'FAQList.Base.module';

.FAQList {
    $root: &;

    border-color: palette(black, 15);

    &__Title {
        color: palette(black, 86);
    }
}

