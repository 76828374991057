@import 'Styles/Pahlmans/includes';

/**
*
* EventExpandableContact
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventExpandableContact.Base.module.scss';

.EventExpandableContact {
    border-color: palette(black, 15);

    &__Title {
        @include media(sm) {
            font-size: 2rem;
        }
    }
}
