@import 'Styles/Base/includes';

/**
*
* FAQList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FAQList {
    $root: &;

    border-bottom: 1px solid black;

    &__Title {
        margin-bottom: 12px;
        font-size: 2rem;
        line-height: 1.27;

        @include media(sm) {
            margin-bottom: 16px;
            font-size: 2.2rem;
        }
    }
}
