@import 'Styles/Base/includes';

/**
*
* Quote
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Quote {
    $root: &;

    width: 100%;
    margin: 40px 0;

    @include media(md) {
        margin: 60px 0;
    }

    &--Wide {
        @include media(xl) {
            transform: translateX(-80px);
            width: calc(100% + 160px);
        }
    }

    &__Blockquote {
        text-align: center;
    }

    &__Title {
        position: relative;
        font-size: 2.8rem;
        line-height: 1.2;

        @include media(sm) {
            font-size: 3.2rem;
        }

        @include media(md) {
            font-size: 4rem;
        }
    }

    &__Cite {
        margin-top: 24px;
        display: block;
    }

    &__Name {
        font-size: 1.6rem;
    }
}
