@import 'Styles/Medborgarskolan/includes';

/**
*
* FAQItem
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'FAQItem.Base.module';

.FAQItem {
    $root: &;

    border-color: $blue-20;

    &__Heading {
        color: $blue;
        font-family: $font-heading;
        font-size: 2rem;

        @include media(sm) {
            font-size: 2.2rem;
        }
    }

    &__Icon {
        background-color: white;
        border: 2px solid $blue;
        border-radius: 100%;

        &::before,
        &::after {
            background: $blue;
        }
    }
}
