@import 'Styles/Kulturama/includes';

/**
*
* Share
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import './Share.Base.module.scss';

.Share {
    $root: &;

    &__Title {
        color: palette(black, 78);
        font-weight: $medium;
    }

    &__Item {
        background-color: palette(black, 5);

        &::before {
            border-color: black;
        }

        &--Link {
            display: none;
        }

        &--Facebook::after {
            background-image: url('#{$basepath}img/bg-icons/base/facebook--black.svg');
        }

        &--Twitter::after {
            background-image: url('#{$basepath}img/bg-icons/base/twitter--black.svg');
        }

        &--LinkedIn::after {
            background-image: url('#{$basepath}img/bg-icons/base/linkedin--black.svg');
        }

        &--Mail::after {
            background-image: url('#{$basepath}img/bg-icons/base/mail--black.svg');
        }
    }
}
