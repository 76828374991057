@import 'Styles/Dansskolanentre/includes';

/**
*
* AccordionItem
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'AccordionItem.Base.module.scss';

.AccordionItem {
    $root: &;

    position: relative;
    border-color: $grey-30;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $grey-5;
        opacity: 0;
        transition: opacity .2s;

        #{$root}--EventExpandableContact & {
            background: transparent;
        }
    }

    &--Open {
        background: $grey-5;

        &::before {
            opacity: 1;
        }
    }

    &__Heading {
        padding: 24px 24px 32px 40px;
        color: $text-color;
        font-family: $font-base;
        font-size: 1.6rem;
        font-weight: $medium;
        transition: color .2s;

        @include media(sm) {
            padding: 24px 40px 32px;
            font-size: 1.8rem;
        }

        &::before {
            top: 31px;
            left: 20px;
            width: 6px;
            height: 6px;
            border-color: $primary-color;
            transition: transform .2s, top .2s, border-color .2s;
        }

        #{$root}--Open & {
            background: $grey-5;
            color: $primary-color;

            &::before {
                top: 34px;
            }

            #{$root}--EventExpandableContact & {
                background: transparent;
            }
        }
    }

    &__Content {
        color: $text-color;

        #{$root}--Open & {
            padding: 24px 24px 50px 24px;

            :global(.Wysiwyg) {
                max-width: 100%;

                > *:first-child {
                    margin-top: 0;
                }
            }

            @include media(sm) {
                padding: 32px 40px 50px;
            }
        }
    }
}
