@import 'Styles/Medborgarskolan/includes';

/**
*
* Quote
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import './Quote.Base.module.scss';

.Quote {
    $root: &;

    &__Mark {
        height: 48px;
        background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/quote--blue.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        margin-bottom: 20px;

        svg {
            display: none;
        }
        
    }

    &__Title {
        font-weight: $bold;
        font-family: $font-heading;
        color: $blue;

        @include media(md) {
            font-size: 3.8rem;
        }
    }

    &__Cite {
        color: $grey-90;
    }
}
