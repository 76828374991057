@import 'Styles/Medborgarskolan/includes';

/**
*
* EventFacts
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventFacts.Base.module.scss';

.EventFacts {
    $root: &;
    background-color: $blue-20;

    &__Container {
        @include u-wrap(site);

        padding-top: 20px;
        padding-bottom: 12px;

        @include media(md) {
            padding-top: 40px;
            padding-bottom: 12px;
        }
    }

    &__Item {
        flex-direction: column;
        width: calc(55% - 20px);
        margin-bottom: 20px;

        @include media(md) {
            width: calc(#{percentage(1/3)} - 20px);
            margin-bottom: 40px;
        }

        @include media(lg) {
            width: calc(25% - 20px);
        }
    }

    &__Label {
        width: 120px;
        font-size: 1.4rem;

        @include media(sm) {
            width: auto;
            font-size: 1.6rem;
        }
    }

    &__Value {
        font-family: $font-heading;
        font-size: 2rem;
        font-weight: $bold;
        color: $blue;

        @include media(sm) {
            font-size: 2.8rem;
        }
    }
}
