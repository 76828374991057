@import 'Styles/Kulturama/includes';

/**
*
* AccordionList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'AccordionList.Base.module.scss';

.AccordionList {
    border-color: palette(black, 10);

    &__Title {
        font-size: 1.8rem;

        @include media(sm) {
            font-size: 2rem;
        }
    }
}
