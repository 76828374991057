@import 'Styles/Dansskolanentre/includes';

/**
*
* LinkList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'LinkList.Base.module';

.LinkList {
    $root: &;

    margin-top: 32px;
    margin-bottom: 40px;

    @include media(sm) {
        margin-top: 56px;
        margin-bottom: 64px;
    }

    @include media(lg) {
        margin-top: 72px;
        margin-bottom: 96px;
    }

    &__Container {
    }

    &__Title {
        margin-bottom: 24px;
        font-size: 3rem;
        font-weight: $bold;

        @include media(md) {
            margin-bottom: 32px;
            font-size: 3.2rem;
        }
    }

    &__Text {
        margin-top: -16px;
        margin-bottom: 24px;

        @include media(md) {
            margin-bottom: 32px;
        }
    }

    &__List {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 12px;

        @include media(xs) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media(sm) {
            gap: 16px;
            grid-template-columns: repeat(3, 1fr);
        }

        @include media(md) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &__Item {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        min-height: 72px;
        padding: 16px;
        color: white;
        background: $gradient-color;
        border-radius: 8px;

        @include media(sm) {
            padding: 24px;
            min-height: 104px;
            border-radius: 12px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $primary-color;
            border-radius: 8px;
            opacity: 0;
            transition: opacity .3s ease-in-out;

            @include media(sm) {
                border-radius: 12px;
            }
        }

        &:hover::before {
            opacity: 1;
        }
    }

    &__ItemContainer {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding-right: 52px;
    }

    &__Link {
        z-index: z('EventSearchInput__Select');
    }

    &__ItemTitle {
        font-weight: $bold;
        font-family: $font-base;
        font-size: 1.8rem;
        line-height: 1.4;
        word-break: break-word;

        @include media(sm) {
            font-size: 2rem;
        }
    }

    &__ItemLabel {
        padding: 0;
    }

    &__ItemText {
        display: none;
    }

    &__Button {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 44px;
        height: 44px;
        margin: auto;
        background-image: url('#{$basepath}img/bg-icons/dansskolanentre/arrow--white.svg');
        background-position: center;
        background-color: rgba(white, 0.2);
        background-repeat: no-repeat;
        background-size: 24px;
        border-radius: 100%;

        svg {
          display: none;
        }

        span {
            display: none;
        }
    }

    &__Arrow--Medborgarskolan {
        display: none;
    }
}
