@import 'Styles/Dansskolanentre/includes';

/**
*
* LinkListImage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.LinkListImage {
    $root: &;

    margin: 40px 0;

    @include media(md) {
        margin: 60px 0;
    }

    &__Container {
        @include u-wrap(site);
    }

    &__Title {
        font-size: 3rem;
        margin-bottom: 32px;

        @include media(sm) {
            font-size: 3.2rem;
        }
    }

    &__Text {
        max-width: map-get($maxwidths, content);
        margin-top: -24px;
        margin-bottom: 32px;
        font-size: 1.8rem;
        font-weight: $light;
    }

    &__List {
        display: flex;
        flex-wrap: wrap;
        margin-left: -12px;

        @include media(sm) {
            margin-left: -16px;
        }
    }

    &__Item {
        display: flex;
        justify-content: stretch;
        position: relative;
        width: calc(100% - 12px);
        margin-left: 12px;
        margin-bottom: 12px;
        border-radius: 8px;
        overflow: hidden;
        background: $red-5;

        @include media(sm) {
            width: calc(100% - 16px);
            margin-left: 16px;
            margin-bottom: 16px;
            border-radius: 12px;
        }

        @include media(md) {
            width: calc(50% - 16px);
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: z('EventSearchInput__Select');
    }

    &__ItemContainer {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex: 0 0 auto;
        position: relative;

        @include media(xs) {
            flex-wrap: nowrap;
        }
    }

    &__ImageContainer {
        position: relative;
        width: 100%;
        height: 180px;
        flex: 1 1 auto;
        overflow: hidden;

        @include media(xs) {
            width: 45%;
            max-width: 266px;
            height: auto;
        }
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateZ(0) scaleX(1);
        transition: transform .3s ease-in-out;

        #{$root}__Item:hover & {
            transform: translateZ(0) scale3d(1.05, 1.05, 1) translateY(-4px);
            
            @include media(sm) {
                transform: translateZ(0) scale3d(1.05, 1.05, 1) translateX(-6px);
            }

            @media(hover: none) {
                transform: translateZ(0) scaleX(1);
            }
        }
    }

    &__Content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1 0 auto;
        padding: 24px 16px;
        z-index: 1;

        @include media(xs) {
            width: 55%;
            padding: 32px 24px;
        }

        @include media(md) {
            padding: 40px 32px;
        }
    }

    &__Tag {
        margin-bottom: 8px;
        color: $red-60;
        font-family: $font-base;
        font-size: 1.4rem;
        font-weight: $medium;
        letter-spacing: .7px;
        line-height: 1.17;
        text-transform: uppercase;
    }

    &__ItemTitle {
        margin-bottom: 24px;
        font-weight: $bold;
        line-height: 1.37;
        font-size: 2.4rem;

        @include media(xs) {
            font-size: 2rem;
            line-height: 1.4;
        }

        @include media(sm) {
            margin-bottom: 37px;
        }

        #{$root}__Item:hover & {
            color: black;

            @media(hover: none) {
                color: inherit;
            }
        }
    }

    &__ItemText {
        display: none;
    }

    &__ItemLabel {
        position: relative;
        display: inline-flex;
        align-items: center;
        padding-right: 23px;
        cursor: pointer;

        svg {
            margin-left: 10px;
        }
    }

    &__Button {
        display: block;
        position: relative;
        background: $gradient-color;
        width: 44px;
        height: 44px;
        border-radius: 100%;
        margin-top: auto;

        &::before {
            content: '';
            position: relative;
            top: 10px;
            left: 10px;
            width: 24px;
            height: 24px;
            display: block;
            background: url('#{$basepath}img/bg-icons/dansskolanentre/arrow--white.svg') no-repeat center center;
            background-position: center;
            z-index: 2;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $primary-color;
            border-radius: 100%;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;

            #{$root}__Item:hover & {
                opacity: 1;
            }
        }

        svg,
        span {
            display: none;
        }
    }
}
