@import 'Styles/Base/includes';

/**
*
* Reviews
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Reviews {
    $root: &;

    &__Title {
        margin-bottom: 32px;
        font-size: 2.8rem;

        @include media(sm) {
            margin-bottom: 40px;
            font-size: 3.2rem;
        }
    }

    &__Item {
        margin-top: 24px;

        @include media(sm) {
            margin-top: 32px;
        }
    }
}
