@import 'Styles/Pahlmans/includes';

/**
*
* EventFacts
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventFacts.Base.module.scss';

.EventFacts {
    $root: &;

    &--Calendar {
        background-color: palette(black,3);
    }

    &__Container {
        width: 100%;
        padding-top: 20px;

        #{$root}--Calendar & {
            @include u-wrap(site);
            
            padding-top: 45px;
            padding-bottom: 40px;

            @include media(md) {
                padding-top: 60px;
                padding-bottom: 60px;
            }
        }
    }

    &__Title {
        display: none;

        @include media(sm) {
            display: block;
            margin-bottom: 20px;
            font-family: $font-heading;
            font-weight: $medium;
            font-size: 2.4rem;
            letter-spacing: 1px;
        }
    }

    &__List {
        width: 100%;

        @include media(sm) {
            margin-left: -20px;
        }
    }

    &__Item {
        margin-bottom: 20px;

        @include media(sm) {
            display: flex;
            flex-direction: column;
            width: calc(50% - 20px);
            margin-left: 20px;
        }

        @include media(md) {
            width: calc(#{percentage(1/3)} - 20px);
        }
    }

    &__Label {
        margin-bottom: 4px;
        color: $primary-color;
        font-size: 1.1rem;
        font-weight: $bold;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    &__Value {
        font-size: 1.6rem;

        a {
            color: $primary-color;
        }
    }
}
