@import 'Styles/Kulturama/includes';

/**
*
* EventSearchSubjectList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EventSearchSubjectList {
    $root: &;

    background: palette(black, 5);

    &__Search {
        @include u-wrap(site);

        position: relative;
        padding-top: 60px;
        padding-bottom: 60px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 12px;
            right: 12px;
            height: 1px;
            max-width: map-get($maxwidths, site);
            background: #d6d6d6;

            @include media(sm) {
                left: 20px;
                right: 20px;
            }

            @include media(lg) {
                left: 30px;
                right: 30px;
            }

            @include media(xl) {
                left: 60px;
                right: 60px;
            }

            #{$root}--NoSubjects & {
                display: none;
            }
        }
    }

    &__SearchTitle {
        margin-bottom: 28px;
        text-align: center;
    }

    &__Grid {
        background: transparent;

        @include media(md) {
            padding-top: 40px;
        }

        @include media(lg) {
            padding-top: 80px;
        }
    }
}
