@import 'Styles/Kulturama/includes';

/**
*
* FAQItem
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'FAQItem.Base.module';

.FAQItem {
    $root: &;

    border-color: palette(black, 5);

    &__Heading {
        color: palette(black, 80);
    }

    &__Icon {
        background-color: palette(pink, d);
    }
}
