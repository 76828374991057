@import 'Styles/Tillskararakademin/includes';

/**
*
* ExtendedWysiwyg
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ExtendedWysiwyg.Base.module';

.ExtendedWysiwyg {
    $root: &;

    &__Block {
        &--HtmlSnippet {
            table {
                width: 100%;
            }

            input[type=text] {
                font-size: 1.6rem;
                border: 1px solid palette(black, 10);
                padding: 20px;
                margin-bottom: 15px;
                width: 100%;
            }

            input[type=submit] {
                @include u-button(solid green);
                font-size: 1.6rem;
                height: 50px;
            }
        }
    }
}
