@import 'Styles/Kulturama/includes';

/**
*
* Quote
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import './Quote.Base.module.scss';

.Quote {
    $root: &;

    &__Mark {
        svg {
            fill: palette(pink);
        }
    }

    &__Title {
        font-weight: $black;
        color: palette(pink);
    }
}
