@import 'Styles/Base/includes';

/**
*
* EventExpandableContact
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EventExpandableContact {
    border-bottom: 1px solid;

    &__Title {
        margin-bottom: 20px;
        font-size: 1.8rem;
    }

    &__Name {
        :global(.Wysiwyg) & {    
            font-weight: $medium;
            margin-top: 40px;
        }
    }
}
