@import 'Styles/Dansskolanentre/includes';

/**
*
* MenuLanding
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'MenuLanding.Base.module';

.MenuLanding {
    $root: &;

    position: relative;
    border-color: $red-15;
    background: $red-5;

    &::before {
        content: '';
        display: none;
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 100px;
        background: $red-5;
        z-index: -1;

        #{$root}--HeroIsAbove & {
            display: block;
        }
    }

    &__Item {
        margin-right: 32px;
    }

    &__Link {
        color: black;
        font-size: 1.6rem;

        &::before {
            right: -12px;
            left: -12px;
            height: 6px;
            background: $primary-color;
        }

    }
}
