@import 'Styles/Tillskararakademin/includes';

/**
*
* LinkList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'LinkList.Base.module';
 
.LinkList {
    $root: &;
    padding-top: 45px;
    padding-bottom: 45px;

    @include media(md) {
        padding-top: 90px;
    }

    :global(.PageHomeSchool) & {
        padding-bottom: 0px;
        @include media(md) {
            padding-bottom: 45px;
        }
    }

    &__Container {

        @include media(lg) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include media(xl) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &__Title {
        margin: 0 10px 35px;

        @include media(md) {
            margin: 0 0 40px;
        }
    }

    &__Text {
        margin-top: -30px;
        margin-bottom: 35px;
        @include media(md) {
            margin-bottom: 40px;
        }
    }

    &__List {
        display: flex;
        flex-flow: column;

        @include media(md) {
            margin: 0;
            flex-flow: row wrap;
            margin-left: -20px;
            justify-content: space-between;
        }

        @include media(xl) {
            margin-left: -32px;
        }
    }

    &__Item {
        margin-bottom: 20px;
        align-self: stretch;
        border-left: 3px solid palette(green);
        padding: 24px 0px;
        background: #f9f9f9;
        transition: background-color .3s ease-in-out;

        @include media(md) {
            margin-bottom: 20px;
            margin-left: 20px;
            padding: 32px 0px;
            width: calc(33% - 20px);
            max-width: 272px;

            :global(.PageHomeSchool) & {
                width: calc(25% - 20px);
            }
        }

        @include media(xl) {
            margin-left: 32px;
            width: calc(33% - 32px);

            :global(.PageHomeSchool) & {
                width: calc(25% - 32px);
            }
        }

        &:hover {
            background: rgba(palette(green), .13);
        }
    }

    &__ItemContainer {
        display: flex;
        padding-left: 20px;
        flex-flow: column;

        :global(.PageHomeSchool) & {
            flex-flow: row;
            align-items: center;
        }
    }

    &__Link {
        z-index: z('EventSearchInput__Select');
    }

    &__ItemTitle {
        font-weight: $bold;
        font-family: $font-heading;
        font-size: 2.4rem;

        @include media(sm) {
            font-size: 2.8rem;
        }

        @include media(xl) {
            font-size: 3.2rem;
        }

        #{$root}__Item:hover & {
            color: black;

            @media(hover: none) {
                color: inherit;
            }
        }
    }

    &__ItemText {
        :global(.PageHomeSchool) & {
            display: none;
        }
    }

    &__Button {
        display: none;

        :global(.PageHomeSchool) & {
            display: inline-flex;
            justify-content: center;
            background-image: url('#{$basepath}img/bg-icons/tillskararakademin/arrow.svg');
            width: 25px;
            height: 18px;
            background-repeat: no-repeat;

            svg {
              display: none;
            }

            span{
                display: none;
            }

            #{$root}__Item:hover & {
                text-decoration: underline;

                @media(hover: none) {
                    text-decoration: none;
                }
            }
        }
    }

    &__Arrow--Medborgarskolan {
        display: none;
    }
}
