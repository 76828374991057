@import 'Styles/Base/includes';

/**
*
* MenuLanding
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.MenuLanding {
    border-bottom: 1px solid;

    &__List {
        @include u-wrap(site);
        display: flex;
        white-space: nowrap;
        overflow-x: auto;
        -ms-overflow-style: none;
    }

    &__Item {
        margin-right: 30px;
    }

    &__Link {
        position: relative;
        display: inline-block;
        padding: 20px 0;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            opacity: 0;
            transition: opacity ease-in-out 0.3s;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }

    }
}
