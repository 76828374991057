@import 'Styles/Pahlmans/includes';

/**
*
* CardImageText
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardImageText.Base.module.scss';

.CardImageText {

    &__Image {

        @include media(xs) {
            margin-right: 20px;
            max-width: 30%;
        }

        @include media(sm) {
            margin-right: 30px;
            max-width: 40%;
        }
    }

    &__Text {
        color: palette(black, 22);
    }
}
