@import 'Styles/Base/includes';

/**
*
* LinkList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.LinkList {
    $root: &;

    &__Container {
        @include u-wrap(site);
    }

    &__Text {
        max-width: map-get($maxwidths, content);
    }

    &__Item {
        position: relative;
        width: 100%;
    }

    &__Link {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    &__ItemLabel {
        position: relative;
        display: inline-flex;
        align-items: center;
        padding-right: 23px;
        cursor: pointer;

        svg {
            margin-left: 10px;
        }
    }

    &__ItemTitle {
        color: inherit;
    }
}
